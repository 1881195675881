import { View } from 'react-native';
import { useThemedStyleFunction } from "../../theme-provider";
import { ThemedText } from '../../themed-text';
import { Aoi, AoiCategory } from '../../../interfaces';
import { getTranslatedProperty } from '../../../utils/localization.utils';
import { detailsContentStyle } from '../details-content.style';

type AoiDetailsContentTopProps = Aoi & {
    category: AoiCategory,
};

const AoiDetailsContentTop = (props: AoiDetailsContentTopProps) => {
    const {category, areas} = props;

    const categoryName = getTranslatedProperty('name', category);

    const formattedAreas = areas.map((area => getTranslatedProperty('name', area)?.toLowerCase()));
    const metadata = [categoryName, ...formattedAreas].join(', ');

    const style = useThemedStyleFunction(detailsContentStyle);

    return (
        <View style={style.container}>
            <View style={[style.row, style.paragraph]}>
                <ThemedText style={[style.standard, style.secondary]}>{metadata}</ThemedText>
            </View>
        </View>
    )
};

export { AoiDetailsContentTop };