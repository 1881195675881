import { Theme } from "@kullaberg/shared";
import { Dimensions, StyleSheet } from "react-native";

const mapPanelStyle = (theme: Theme) => {
    const {width} = Dimensions.get('window');

    return StyleSheet.create({
        container: {
            //
        },
        map: {
            width: '100%',
            height: '100%',
            minWidth: width < 2 * 420 
                ? 420 
                : width < 420
                ? theme.units.vw
                : undefined,
        },
        gradient: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 4.8 * theme.units.base,
        },
        zoom: {
            position: 'absolute',
            right: theme.spacing.sides,
            bottom: theme.spacing.sides,
        },
        zoomInButton: {
            backgroundColor: theme.color.listBackground,
            padding: theme.spacing.half,
            paddingBottom: theme.spacing.sliver,
            borderTopLeftRadius: theme.borderRadius.large,
            borderTopRightRadius: theme.borderRadius.large,
        },
        zoomOutButton: {
            backgroundColor: theme.color.listBackground,
            marginTop: theme.spacing.sliver,
            padding: theme.spacing.half,
            paddingTop: theme.spacing.sliver,
            borderBottomLeftRadius: theme.borderRadius.large,
            borderBottomRightRadius: theme.borderRadius.large,
        },
        zoomButtonText: {
            textAlign: 'center',
            fontSize: theme.fontSize.large * 2,
        },
        loadingOverlay: {
            position: 'absolute',
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
            opacity: 0.5,
            textAlign: 'center',
            justifyContent: 'center',
        },
        spinner: {
            flex: 1,
            width: '100%',
            height: '100%',
        },
    });
};

export { mapPanelStyle };