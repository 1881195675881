import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import {
  Pressable,
  SafeAreaView,
  View,
} from 'react-native';
import './localization';
import {
  ThemeProvider, useThemedStyleFunction
} from '@kullaberg/shared';
import { MapPanel, SideBarPanel } from './components';
import { fetchData } from './services';
import { NavigationContainer } from '@react-navigation/native';
import ImageViewer from 'react-simple-image-viewer';
import { useImageViewerStore, useVideoViewerStore } from './stores';
import { shallow } from 'zustand/shallow';
import ReactPlayer from 'react-youtube';
import { CloseIcon } from './assets';
import {rootStyles }  from './index.style';

interface AppProps {
  apiUrl: string;
};

const App = (props: AppProps) => {
  const {apiUrl} = props;

  // Fetch data based on filters
  useEffect(() => {
    fetchData(apiUrl)
    .then(() => {
      console.log('finished loading data');
    });
  }, []);

  const {visible, images, current, hideImageViewer} = useImageViewerStore((state) => state, shallow);
  const {showVideo, videoId, hideVideoViewer} = useVideoViewerStore((state) => state, shallow);

  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  const rootStyle = useThemedStyleFunction(rootStyles);

  return (
    <NavigationContainer>
      <ThemeProvider>
        <SafeAreaView style={rootStyle.root}>
          <SideBarPanel style={rootStyle.sideBar}/>
          <MapPanel style={rootStyle.map}/>

          { visible && 
            <ImageViewer src={images} currentIndex={current} onClose={hideImageViewer}/>
          }
          { showVideo && 
            <Pressable style={rootStyle.videoModal} onPress={()=>hideVideoViewer()}>
              <View style={rootStyle.iconContainer}>
                <CloseIcon onPress={()=>hideVideoViewer()}/>
              </View>
              <ReactPlayer opts={opts} videoId={videoId} />
            </Pressable> 
          }
        </SafeAreaView>
      </ThemeProvider>
    </NavigationContainer>
  );
}

export default App;