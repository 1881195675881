import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";
import { colors } from "../../utils";

const socialMediaStyle = (theme: Theme) => StyleSheet.create({
    container: {
        borderTopColor: colors.neutralColor50,
        borderTopWidth: theme.units.base * 0.1,
        paddingTop: theme.spacing.half,
        flexDirection: 'row',
        marginBottom: theme.spacing.standard,
    }, 
    iconWrapper: {
        marginRight: theme.spacing.sliver
    }
});

export { socialMediaStyle };