import { StyleSheet } from "react-native";
import { boxShadow, Theme } from "@kullaberg/shared";

const sideBarStyle = (theme: Theme) => StyleSheet.create({
    container: {
        height: '100%',
        maxHeight: 100 * theme.units.vh,
        backgroundColor: theme.color.listBackground,
        ...boxShadow(theme.units.base, 'rgba(0, 0, 0, 0.2)', 'rgba(0, 0, 0, 0.4)'),
    },

    crop: {
        flex: 1,
        overflow: 'hidden',
        maxWidth: 100 * theme.units.vw,
    },

    content: {
        flex: 1,
        backgroundColor: theme.color.listBackground,
        flexDirection: 'column',
        maxWidth: 100 * theme.units.vw,
    },
});

export { sideBarStyle };