import { Geometry, LineString, Point, Polygon} from 'ol/geom';
import { Vector as VectorLayer, VectorImage} from 'ol/layer';
import { Vector as VectorSource, Cluster } from 'ol/source';
import { Style, Fill, Stroke,Text, Icon, Circle as CircleStyle } from 'ol/style';
import { asArray as colorAsArray, asString as colorAsString } from 'ol/color';
import { Feature } from 'ol';
import { ICONS, OBJECT_TYPE, OBJECT_ID } from '../constants';
import { FeatureType, PathDirection } from '../enums';
import { COLORS } from '../constants';
import { transform } from 'ol/proj';
import { getDistance } from 'ol/sphere';

const DEFAULT_ICON_FONT = 'interRegular';
const DEFAULT_PATH_FONT = 'interRegular';
const DEFAULT_PATH_ICON_SIZE = 44;
const DEFAULT_POI_ICON_SIZE = 28;
const ICON_SELECTION_PADDING = 4;
const CLOSED_TEXT = 'Stängd';
let renderScale = 0.5;

/**
 * Gets the render scale variable
 */
const getRenderScale = (): number => {
    return renderScale;
};

/**
 * Sets the render scale variable
 */
const setRenderScale = (scale: number) => {
    renderScale = scale;
};

/**
 * Creates a empty vector source
 */
const createEmptyVectorSource = (): VectorSource<Feature<Geometry>> => {
    return new VectorSource<Feature<Geometry>>({
        features: [],
    });
};

/**
 * Creates a empty vector layer for a source
 */
const createEmptyVectorLayer = (source: VectorSource<Feature<Geometry>>): VectorImage<Feature<Geometry>> => {
    return new VectorImage({
        source,
    });
};

/**
 * Creates a cluster layer for the given vector source using the given icon path and color
 */
const createIconClusterLayer = (source: VectorSource<Feature<Geometry>>, path: string, color: string): VectorImage<Feature<Geometry>> => {
    const cluster = new Cluster({
        distance: 40,
        source,
    });

    return new VectorImage({
        source: cluster,
        style: (feature) => {
            const size = feature.get('features').length;

            if (size === 1) {
                return feature.get('features')[0]['style_'];
            }

            return createBaseMapIconStyle(path, `(${size})`, color, 30, DEFAULT_ICON_FONT, 45, DEFAULT_PATH_ICON_SIZE);
        },
    });
};

/**
 * Creates a set of base styles for a icon
 */
const createBaseMapIconStyle = (
    iconPath: string,
    name: string | undefined,
    color: string,
    fontSize: number,
    fontFamily: string,
    textOffset: number,
    backplateSize: number,
    inverted: boolean = true,
): Style[] => {
    return [
        new Style({
            image: createCircleBackingPlateStyle(backplateSize),
            text: new Text({
                text: name,
                font: `${fontSize}px ${fontFamily}`,
                offsetY: textOffset * renderScale,
                scale: renderScale,
                stroke: new Stroke({
                    color: inverted ? '#000000' : '#FFFFFF',
                    width: 8,
                }),
                fill: new Fill({
                    color: inverted ? '#FFFFFF' : color,
                }),
            }),
        }),
        new Style({
            image: new Icon({
                src: iconPath,
                scale: renderScale,
                crossOrigin: 'anonymous',
                color,
            }),
        }),
    ];
};

/**
 * Creates a circle style, mainly used behind icons
 */
const createCircleBackingPlateStyle = (radius: number = 0): CircleStyle => {
    return new CircleStyle({
        radius: radius * renderScale,
            fill: new Fill({
            color: '#FFFFFF',
        }),
    });
};

/**
 * Creates a new color using the given hex color string and a alpha value
 */
const colorWithAlpha = (color: string, alpha: number): string => {
    const [r, g, b] = Array.from(colorAsArray(color));
    return colorAsString([r, g, b, alpha]);
};

/**
 * Creates a base feature for a icon using the given style
 */
const createBaseMapIconFeature = (
    point: Point,
    style: Style | Style[],
    type?: string,
    id?: string,
): Feature<Geometry> => {
    const feature = new Feature<Geometry>({
        geometry: point,
    });
    feature.setStyle(style);
    feature.set(OBJECT_TYPE, type);
    feature.set(OBJECT_ID, id);
    return feature;
};

/**
 * Creates a styled feature for a gps indicator icon
 */
const createGpsIndicatorFeature = (point: Point, heading?: number): Feature<Geometry> => {
    const feature = new Feature({
        geometry: point,
    });

    const style = new Style({
        image: new Icon({
            src: ICONS.GPS_POSITION,
            scale: renderScale,
            rotation: heading ?? 0,
        }),
    });
    feature.setStyle(style);
    return feature;
};

/**
 * Creates a styled feature for a snowmobile path icon
 */
const createSnowmobilingIconFeature = (
    point: Point,
    name: string | undefined,
    isBold: boolean = false,
): Feature<Geometry> => {
    return createBaseMapIconFeature(
        point,
        createBaseMapIconStyle(
            ICONS.PATHS.SNOWMOBILING,
            name,
            COLORS.PATHS.SNOWMOBILING,
            32,
            DEFAULT_ICON_FONT,
            64,
            isBold ? DEFAULT_PATH_ICON_SIZE + ICON_SELECTION_PADDING : DEFAULT_PATH_ICON_SIZE,
            false,
        ),
    );
};

/**
 * Creates a styled feature for a common path icon
 */
const createCommonPathIconFeature = (
    point: Point,
    id: string,
    name: string | undefined,
    color: string,
    iconPath: string,
    isBold: boolean = false
): Feature<Geometry> => {
    return createBaseMapIconFeature(
        point,
        createBaseMapIconStyle(
            iconPath,
            name,
            color,
            32,
            DEFAULT_ICON_FONT,
            64,
            isBold ? DEFAULT_PATH_ICON_SIZE + ICON_SELECTION_PADDING : DEFAULT_PATH_ICON_SIZE,
            false,
        ),
        FeatureType.Path,
        id,
    );
};

/**
 * Creates a styled feature for a common poi icon
 */
const createCommonPoiIconFeature = (
    point: Point,
    id: string,
    name: string | undefined,
    color: string,
    iconPath: string,
    inverted: boolean = false,
    isBold: boolean = false,
): Feature<Geometry> => {
    return createBaseMapIconFeature(
        point,
        createBaseMapIconStyle(
            iconPath,
            name,
            color,
            24,
            DEFAULT_ICON_FONT,
            42,
            isBold ? DEFAULT_POI_ICON_SIZE + ICON_SELECTION_PADDING : DEFAULT_POI_ICON_SIZE,
            inverted,
        ),
        FeatureType.Poi,
        id,
    );
};

/**
 * Creates a styled feature for a common aoi icon
 */
const createCommonAoiIconFeature = (
    point: Point,
    id: string,
    name: string | undefined,
    color: string,
    iconPath: string,
    inverted: boolean = false,
    isBold: boolean = false,
): Feature<Geometry> => {
    return createBaseMapIconFeature(
        point,
        createBaseMapIconStyle(
            iconPath,
            name,
            color,
            24,
            DEFAULT_ICON_FONT,
            42,
            isBold ? DEFAULT_POI_ICON_SIZE + ICON_SELECTION_PADDING : DEFAULT_POI_ICON_SIZE,
            inverted,
        ),
        FeatureType.Aoi,
        id,
    );
};

/**
 * Creates a styled feature for a path endcap icon
 */
const createEndcapIconFeature = (point: Point, name: string | undefined, inverted: boolean, color: string = COLORS.PATHS.ENDCAP): Feature<Geometry> => {
    return createBaseMapIconFeature(
        point,
        createBaseMapIconStyle(
            ICONS.PATHS.ENDCAP,
            name,
            color,
            20,
            DEFAULT_ICON_FONT,
            30,
            12,
            inverted,
        ),
    );
};

/**
 * Creates a set of base styles for paths
 */
const createBasePathStyles = (
    color: string,
    opacity: number,
    width: number = 6,
    outline: number = 8,
): Style[] => {
    return [
        new Style({
            stroke: new Stroke({
                color: '#FFFFFF',
                width: (width + outline) * renderScale,
            }),
        }),
        new Style({
            stroke: new Stroke({
                color: colorWithAlpha(color, opacity),
                width: width * renderScale,
            }),
        }),
    ];
};

/**
 * Creates a set of base styles for aois
 */
const createBaseAoisStyles = (
    color: string,
    opacity: number = 0.8,
    width: number = 6,
    outline: number = 8,
): Style[] => {
    return [
        new Style({
            stroke: new Stroke({
              color: color,
              width: (width + outline) * renderScale,
            }),
          }),
          new Style({
            fill: new Fill({color: colorWithAlpha(color, opacity)}),
          }),
    ];
};

/**
 * Creates a base text style for paths
 */
const createBasePathTextStyle = (color: string, name?: string): Style => {
    return new Style({
        text: new Text({
            text: name,
            font: `20px ${DEFAULT_PATH_FONT}`,
            scale: renderScale,
            stroke: new Stroke({
                color: '#FFFFFF',
                width: 8,
            }),
            fill: new Fill({
                color,
            }),
        }),
    });
};

/**
 * Creates a set of styled base featureas for a path using the given style
 */
const createBasePathFeatures = (
    coordinates: number[][],
    type: string,
    id: string,
    texts: {color: string, name: string, position: number}[],
    pathStyle: Style | Style[],
    pathDirection?: PathDirection,
): Feature<Geometry>[] => {
    const features = [];
    const pathFeature = new Feature({
        geometry: new LineString(coordinates, 'XYM'),
    });
    pathFeature.set(OBJECT_TYPE, type);
    pathFeature.set(OBJECT_ID, id);
    pathFeature.setStyle(pathStyle);
    features.push(pathFeature);

    // Place arrows along path
    if (pathDirection !== undefined) {
        const absArrowSpacing = 150;
        let traversedSpace = 0;
        coordinates.forEach((current, index) => {
            if (index > 0) {
                const previous = coordinates[index - 1];
        
                const projection = 'EPSG:4326';
                const sourceProj = 'EPSG:3857';
                const c1 = transform(previous, sourceProj, projection);
                const c2 = transform(current, sourceProj, projection);
                const dist = getDistance(c1, c2);

                // Check if we've gone long enough along path without an arrow
                if (Math.ceil(traversedSpace / absArrowSpacing) < Math.ceil((traversedSpace + dist) / absArrowSpacing)) {
                    const angOfs = pathDirection === PathDirection.Reverse ? Math.PI : 0;
                    const rotation = Math.atan2(current[0] - previous[0], current[1] - previous[1]) + angOfs;
                    const arrowFeature = new Feature({
                        geometry: new Point(current),
                    });

                    const arrowStyle = new Style({
                        image: new Icon({
                            src: ICONS.DIRECTION_ARROW,
                            scale: renderScale,
                            rotation,
                        }),
                    });
                    arrowFeature.setStyle(arrowStyle);
    
                    features.push(arrowFeature);
                }
                traversedSpace += dist;
            }
        });
    }
    
    texts.forEach(text => {
        const index = Math.floor(coordinates.length * text.position);
        const textFeature = new Feature({
            geometry: new Point(coordinates[index]),
        });
        textFeature.setStyle(createBasePathTextStyle(text.color, text.name));
        features.push(textFeature);
    });
    
    return features;
};

/**
 * Creates a set of styled features for a snowmobile path
 */
const createSnowmobilePathFeatures = (
    coordinates: number[][],
    id: string,
    isClosed: boolean,
    isBold: boolean,
): Feature<Geometry>[] => {
    return createBasePathFeatures(
        coordinates,
        FeatureType.SnowmobileSubPath,
        id,
        isClosed
            ? [{name: CLOSED_TEXT, position: 0.5, color: COLORS.PATHS.SNOWMOBILING}]
            : [],
        createBasePathStyles(
            COLORS.PATHS.SNOWMOBILING,
            isClosed ? 0.3 : 1,
            6,
            isBold ? 16 : 8,
        ),
    );
};

/**
 * Creates a set of styled features for a common path
 */
const createCommonPathFeatures = (
    coordinates: number[][],
    id: string,
    color: string,
    isClosed: boolean,
    isBold: boolean,
    pathDirection?: PathDirection,
): Feature<Geometry>[] => {
    return createBasePathFeatures(
        coordinates,
        FeatureType.Path,
        id,
        isClosed
            ? [
                {name: CLOSED_TEXT, position: 0.25, color},
                {name: CLOSED_TEXT, position: 0.75, color},
            ]
            : [],
        createBasePathStyles(color, isClosed ? 0.3 : 1, 6, isBold ? 16 : 8),
        pathDirection,
    );
};

/**
 * Creates a set of styled base featureas for an aoi using the given style
 */
const createBaseAoiFeatures = (
    coordinates: number[][],
    type: string,
    id: string,
    aoiStyle: Style | Style[],
): Feature<Geometry>[] => {
    const features = [];
    const aoiFeature = new Feature({
        geometry: new Polygon([coordinates]),
    });
    aoiFeature.set(OBJECT_TYPE, type);
    aoiFeature.set(OBJECT_ID, id);
    aoiFeature.setStyle(aoiStyle);
    features.push(aoiFeature);
   
    return features;
};

/**
 * Creates a set of styled features for a common aoi
 */
const createCommonAoiFeatures = (
    coordinates: number[][],
    id: string,
    color: string,
    
): Feature<Geometry>[] => {
    return createBaseAoiFeatures(
        coordinates,
        FeatureType.Aoi,
        id,
        createBaseAoisStyles(color),
    );
};

/**
 * Post a message to the host app
 */
const postMessage = (msg: string) => {
    if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(msg);
    }
};

export {
    getRenderScale,
    setRenderScale,
    createEmptyVectorSource,
    createEmptyVectorLayer,
    createIconClusterLayer,
    createGpsIndicatorFeature,
    createSnowmobilingIconFeature,
    createCommonPathIconFeature,
    createCommonPoiIconFeature,
    createCommonAoiIconFeature,
    createEndcapIconFeature,
    createSnowmobilePathFeatures,
    createCommonPathFeatures,
    postMessage,
    createCommonAoiFeatures
};