import { AoiCategoryResponse, AoiRequirements, AoiResponse } from "../../interfaces";
import { getData } from "./base.utils";

export const getAois = (baseUrl: string, category?: string): Promise<AoiResponse[]> => {
    const path = category ? `/aois?filter={"areaOfInterestCategoryId":"${category}"}`: '/aois/gps';
    return getData<AoiResponse[]>(baseUrl, path);
};

export const getAoiCategories = (baseUrl: string): Promise<AoiCategoryResponse[]> => {
    const path = '/aois/categories';
    return getData<AoiCategoryResponse[]>(baseUrl, path);
};

/**
 * Gets the required data to render the aois, alternatively filter by category
 */
 export const getAoiRequirements = (baseUrl: string, category?: string): Promise<AoiRequirements> => {
    return new Promise<AoiRequirements>((resolve, reject) => {
        Promise.all([
            getAois(baseUrl, category),
            getAoiCategories(baseUrl),
        ]).then((response: [AoiResponse[], AoiCategoryResponse[]]) => {
            const [aois, categories] = response;

            resolve({aois, categories});
        }).catch((error) => reject(error));
    });
};