import { PoiCategoryResponse, PoiRequirements, PoiResponse } from "../../interfaces";
import { getData } from "./base.utils";

export const getPois = (baseUrl: string, category?: string): Promise<PoiResponse[]> => {
    const path = category ? `/pois?filter={"pointOfInterestCategoryId":"${category}"}`: '/pois';
    return getData<PoiResponse[]>(baseUrl, path);
};

export const getPoiCategories = (baseUrl: string): Promise<PoiCategoryResponse[]> => {
    const path = '/pois/categories';
    return getData<PoiCategoryResponse[]>(baseUrl, path);
};

/**
 * Gets the required data to render the pois, alternatively filter by category
 */
 export const getPoiRequirements = (baseUrl: string, category?: string): Promise<PoiRequirements> => {
    return new Promise<PoiRequirements>((resolve, reject) => {
        Promise.all([
            getPois(baseUrl, category),
            getPoiCategories(baseUrl),
        ]).then((response: [PoiResponse[], PoiCategoryResponse[]]) => {
            const [pois, categories] = response;

            resolve({pois, categories});
        }).catch((error) => reject(error));
    });
};