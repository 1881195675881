import { StyleSheet } from "react-native";
import { Theme } from "@kullaberg/shared";

const sideBarPanelStyle = (theme: Theme) => StyleSheet.create({
    container: {
        //
    },
    floatingTouchable: {
        position: 'absolute',
        top: theme.spacing.sides,
        right: -theme.iconSize.medium -theme.spacing.sides,
    },
    banner: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: theme.spacing.sides,
        paddingVertical: theme.spacing.sides,
        backgroundColor: '#758FA6',
    },
    openIcon: {
        width: theme.iconSize.medium,
        height: theme.iconSize.medium,
        color: theme.color.textInverted,
    },
    menuIcon: {
        width: theme.iconSize.large,
        height: theme.iconSize.large,
        color: theme.color.textInverted,
    },
    logo: {
        color: theme.color.textLink,
        height: theme.units.base * 3.2,
    },
    languageSelector: {
        alignItems: 'flex-end'
    }
});

export { sideBarPanelStyle };