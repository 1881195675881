import React from 'react';
import { ActivityIndicator, View, ViewProps } from 'react-native';
import { withExternalStyle } from '../../utils';
import { useThemedStyleFunction } from '../theme-provider';
import { loadingSpinnerStyle } from './loading-spinner.style';

type LoadingSpinnerProps = ViewProps & {
    //
};

const LoadingSpinner = (props: LoadingSpinnerProps) => {
    const {style: styleProp} = props;

    const style = useThemedStyleFunction(loadingSpinnerStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    return (
        <View style={containerStyle}>
            <ActivityIndicator size="large" color={style.indicator.color} />
        </View>
    );
};

export { LoadingSpinner };
