import { PathCategoryResponse, PathRequirements, PathResponse } from "../../interfaces";
import { getData } from "./base.utils";

export const getPaths = (baseUrl: string, category?: string): Promise<PathResponse[]> => {
    const path = category ? `/paths/gps?filter={"pathCategoryId":"${category}"}` : '/paths/gps';
    return getData<PathResponse[]>(baseUrl, path);
};

export const getPathCategories = (baseUrl: string): Promise<PathCategoryResponse[]> => {
    const path = '/paths/categories';
    return getData<PathCategoryResponse[]>(baseUrl, path);
};

/**
 * Get the required data to render paths, alternatively filter by category
 */
 export const getPathRequirements = (baseUrl: string, category?: string): Promise<PathRequirements> => {
    return new Promise<PathRequirements>((resolve, reject) => {
        Promise.all([
            getPaths(baseUrl, category),
            getPathCategories(baseUrl),
        ]).then((response: [PathResponse[], PathCategoryResponse[]]) => {
            const [paths, categories] = response;

            resolve({paths, categories});
        }).catch((error) => reject(error));
    });
};