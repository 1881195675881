import React from 'react';
import { Platform, Text, TextProps } from 'react-native';
import { fonts, withExternalStyle } from '../../utils';

type ThemedTextProps = TextProps & {
    type?: 'light' | 'standard' | 'bold' | 'signature'
};

const ThemedText = (props: ThemedTextProps) => {
    const {style: styleProp, type} = props;
    // Specifying font-weight breaks certain font rendering on Android
    const isIos = Platform.OS === 'ios';

    let fontFamily;
    let fontWeight;
    switch (type) {
        case 'light':
            fontFamily = fonts.light;
            if (isIos) {
                fontWeight = '200';
            }
            break;

        case 'bold':
            fontFamily = fonts.bold;
            if (isIos) {
                fontWeight = '500';
            }
            break;

        case 'signature':
            fontFamily = fonts.signature;
            if (isIos) {
                fontWeight = '900';
            }
            break;

        case 'standard':
        default:
            fontFamily = fonts.standard;
            if (isIos) {
                fontWeight = '400';
            }
            break;
    }

    const fontSize = styleProp
      ? Array.isArray(styleProp)
        ? (styleProp as [{fontSize?: number}]).reduce((size, curr) => curr?.fontSize ?? size, 24)
        : (styleProp as any).fontSize ?? 24
      : 24;
    const lineHeight = fontSize * 1.3;

    const styleObj: any = {
      includeFontPadding: false,
      lineHeight,
      fontFamily,
      fontWeight,
    };
    const style = withExternalStyle(styleObj, styleProp);

    return (
        <Text {...props} style={style} allowFontScaling={false}>
            {props.children}
        </Text>
    );
};

export { ThemedText };
