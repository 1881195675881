import { StyleSheet } from "react-native";
import { Theme } from "../../theme-provider";

const filteredListStyle = (theme: Theme) => StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: theme.color.listBackground,
    }
});

export { filteredListStyle };