const fetchData = <T>(baseUrl: string, path: string, method: string, body?: string, extraHeaders?: {[key: string]: string}): Promise <T> => {
    return new Promise<T>((resolve, reject) => {
        return fetch(`${baseUrl}${path}`, {
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...(extraHeaders ?? {})
            },
            ...(body ? {body}: {}),
        })
        .then(async response => {
            const text = await response.text();

            if (!response.ok) {
                throw text;
            }

            try {
                const json = JSON.parse(text);
                return json;
            }
            catch(error) {
                return text;
            }
        })
        .then(json => resolve(json))
        .catch(errorText => {
            try {
                const errorJson = JSON.parse(errorText);
                reject(errorJson);
            }
            catch(error) {
                reject(errorText);
            }
        });
    });
};

/**
 * GET specified response interface from a path
 */
export const getData = <T>(baseUrl: string, path: string, extraHeaders?: {[key: string]: string}): Promise <T> => {
    return fetchData<T>(baseUrl, path, 'GET', undefined, extraHeaders);
};

/**
 * POST a payload to a path and returns a specified response interface
 */
export const postData = <T>(baseUrl: string, path: string, payload: string, extraHeaders?: {[key: string]: string}): Promise <T> => {
    return fetchData<T>(baseUrl, path, 'POST', payload, extraHeaders);
};

/**
 * PUT a payload to a path and returns a specified response interface
 */
export const putData = <T>(baseUrl: string, path: string, payload: string, extraHeaders?: {[key: string]: string}): Promise<T> => {
    return fetchData<T>(baseUrl, path, 'PUT', payload, extraHeaders);
};

/**
 * PATCH a payload to a path and returns a specified response interface
 */
export const patchData = <T>(baseUrl: string, path: string, payload: string, extraHeaders?: {[key: string]: string}): Promise<T> => {
    return fetchData<T>(baseUrl, path, 'PATCH', payload, extraHeaders);
};

/**
 * DELETE specified data
 */
export const deleteData = (baseUrl: string, path: string, extraHeaders?: {[key: string]: string}): Promise<void> => {
    return fetchData<void>(baseUrl, path, 'DELETE', undefined, extraHeaders);
};