import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";

const imageRowStyle = (theme: Theme) => StyleSheet.create({
    container: {
        flexDirection: 'row',
        height: 166,
        marginBottom: 16
    },
    tile: {
        position: 'relative',
        aspectRatio: 1,
        flexShrink: 1,
        marginRight: 12,
        overflow: 'hidden',
        borderRadius: theme.borderRadius.standard,
        height: '100%'
    },
    image: {
        width: 166,
        height: 166,
        aspectRatio: 1,
    },
    lastImage: {
        marginRight: theme.spacing.sides
    },
    tileOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    excess: {
        margin: 'auto',
        fontSize: theme.fontSize.large,
        color: theme.color.textInverted,
    },
});

export { imageRowStyle };