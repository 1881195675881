
import { StyleSheet } from "react-native";
import { Theme } from "@kullaberg/shared";

const rootStyles = (theme:Theme ) => StyleSheet.create({
    root: {
      height: "100%",
      flexDirection: "row",
      justifyContent: "flex-start",
      backgroundColor: "white",
      overflow: "hidden",
    },
    map: {
      flex: 1,
    },
    sideBar: {
      zIndex: 1,
    },
    videoModal: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.color.black,
      zIndex: 2,
    },
    iconContainer: {  
      width: theme.iconSize.large,
      height: theme.iconSize.large,
      position: 'absolute',
      top: theme.spacing.double,
      right: theme.spacing.double,
      flex: 1,
    },
    icon: {
        position: 'absolute',
        top: theme.spacing.half,
        right: theme.spacing.half,
        flex: 1,
        color: theme.color.textPrimary
    },
  });
  
  export { rootStyles };