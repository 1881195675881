import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";

const loadingSpinnerStyle = (theme: Theme) => StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        justifyContent: 'center',
    },
    indicator: {
        color: theme.color.textLink,
    },
});

export { loadingSpinnerStyle };