import { useCallback } from 'react';
import { Platform, TouchableOpacity, View, Linking } from 'react-native';
import { useTranslation } from "react-i18next";
import { useThemedStyleFunction } from "../../theme-provider";
import { ThemedText } from '../../themed-text';
import { Aoi } from '../../../interfaces';
import { share } from '../../../utils';
import { Share } from '../../../assets';
import { getTranslatedProperty } from '../../../utils/localization.utils';
import { ImageRow } from '../../image-row';
import { detailsContentStyle } from '../details-content.style';
import { SocialMediaList } from '../../social-media-list';

type AoiDetailsContentBottomProps = Aoi & {
    onImagePress?: (images: string[], index: number) => void,
    onVideoPress?: (vidoeId: string) => void,
    youtubeVideoId?: string,
    videoPlayer?: React.FC<{ videoId: string, onPress: (videoId: string) => void }>,
};

const AoiDetailsContentBottom = (props: AoiDetailsContentBottomProps) => {
    const {url, imageUrls, onImagePress, socialMedia, youtubeVideoId, onVideoPress, videoPlayer} = props;
    const hasMedia = (imageUrls?.length ?? 0) > 0 || !!youtubeVideoId;

    const [t] = useTranslation();

    const name = getTranslatedProperty('name', props);
    const description = getTranslatedProperty('description', props);

    const handleUrl = useCallback(async () => {
        if (url === undefined || !url.length) {
            return;
        }
        if (Platform.OS === 'web') {
            //@ts-ignore
            window.open(url, '_newtab');
        } else if (Platform.OS === 'android' || Platform.OS === 'ios') {
            const supported = await Linking.canOpenURL(url);
            if (supported) {
                await Linking.openURL(url);
            } else {
                console.error('Don\'t know how to open URI: ' + url);
            }
        }
    }, [url]);

    const handleShare = useCallback(() => {
        const message = name;
        const url = 'http://www.example.com';

        share(message, url);
    }, [share, name]);

    const handleOnImagePress = useCallback((index: number) => {
            onImagePress?.(imageUrls ?? [], index);
    }, [imageUrls]);

    const handleOnVideoPress = useCallback((id: string) => {
        onVideoPress?.(id);
    }, [youtubeVideoId]);

    const style = useThemedStyleFunction(detailsContentStyle);

    return (
        < > 
            <View style={[style.container]}>
                <View style={style.separatorLine}>
                    <TouchableOpacity style={[style.secondaryHeader, style.row]} onPress={handleUrl}>
                        <ThemedText style={[style.medium, style.link, style.underLine]} type='bold' numberOfLines={1} ellipsizeMode={'tail'}>{url}</ThemedText>
                    </TouchableOpacity>
                    {/* TEMPORARY HIDDEN */}
                    {/* <TouchableOpacity style={[style.row, style.share]} onPress={handleShare}>
                        <ThemedText style={[style.standard, style.link, style.capitalize]} type='bold'>{t('share')}</ThemedText>
                        <Share style={style.shareIcon}/>
                    </TouchableOpacity> */}
                </View>
            </View>

            { hasMedia && (
                <View style={style.imagesContainer}>
                    <ImageRow 
                        slots={4} 
                        images={imageUrls} 
                        onImagePress={handleOnImagePress}
                        videoId={youtubeVideoId}
                        onVideoPress={handleOnVideoPress}
                        videoPlayer={videoPlayer}/>
                </View>
            )}

            { description &&
                <View style={[style.paragraph, style.container]}>
                    <ThemedText style={[style.standard, style.primary]}>{description}</ThemedText>
                </View>
            }
            {socialMedia && 
                <View style={[style.paragraph, style.container]}>
                    <SocialMediaList { ...socialMedia}/>
                </View>
            }
           
        </>
    )
};

export { AoiDetailsContentBottom };