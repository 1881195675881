import { colors } from '@kullaberg/shared/src/utils/variables';

const defaultColor = colors.neutralColor500;

const COLORS = {
    PATHS: {
        CROSS_COUNTRY_SKIING: defaultColor,
        SNOWMOBILING: defaultColor,
        MOUNTAIN_BIKING: defaultColor,
        HIKING: defaultColor,
        ENDCAP: defaultColor,
        NO_CATEGORY: defaultColor,
        RUNNING: defaultColor,
        RIDING: defaultColor,
        CANOE: defaultColor,
        CHAIR_LIFT: defaultColor,
        GONDOLA: defaultColor,
        SURFACE_LIFT: defaultColor,
        BELT_LIFT: defaultColor,
        SKI_SLOPE: defaultColor,
    },
    POIS: {
        NO_CATEGORY: defaultColor,
        RESTAURANT: defaultColor,
        CAFES: defaultColor,
        ATTRACTIONS: defaultColor,
        LOOKOUTS: defaultColor,
        PARKING: defaultColor,
        CABINS: defaultColor,
        SKI_BUS: defaultColor,
        STORES: defaultColor,
        TOILETS: defaultColor,
        WARNING: colors.errorColor500,
        SHELTER: defaultColor,
    },
    AOIS: {
        WARNING_AREA: colors.warningAreaColor,
        IMPORTANT_AREA: colors.importantAreaColor,
        INFO_AREA: colors.areaColor
    },
    DIFFICULTY: {
        VERY_EASY: colors.veryEasyColor,
        EASY: colors.easyColor,
        MEDIUM: colors.mediumColor,
        HARD: colors.hardColor,
    },
    
};

export { COLORS };