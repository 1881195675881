import { useEffect, useRef } from "react";
import { Animated, Easing, View, ViewProps } from "react-native";
import { useThemedStyleFunction, withExternalStyle } from "@kullaberg/shared";
import { sideBarStyle } from "./side-bar.style";

type SideBarProps = {
    maxWidth: number;
    open: boolean;
} & ViewProps;

const SideBar = (props: SideBarProps) => {
    const { style: styleProp, children, open, maxWidth } = props;
    const sideBarAnimation = useRef(new Animated.Value(0)).current;
    
    // Handle change detection of "open" prop
    const previousOpen = useRef<boolean>();
    useEffect(() => {
        Animated.timing(sideBarAnimation, {
            toValue: open ? 1 : 0,
            duration: 200,
            easing: Easing.ease,
            useNativeDriver: false,
        }).start();

        previousOpen.current = open;
    }, [open]);

    const sideBarWidth = sideBarAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, maxWidth],
    });

    const style = useThemedStyleFunction(sideBarStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    return (
        <View style={containerStyle}>
            <Animated.View style={[style.crop, {width: sideBarWidth}]}>
                <View style={[style.content, {width: maxWidth}]}>
                    {children}
                </View>
            </Animated.View>
        </View>
    )
};

export { SideBar };