import { StyleSheet } from "react-native";
import { boxShadow } from "../../../utils";
import { Theme } from "../../theme-provider";

const listEntryStyle = (theme: Theme) => StyleSheet.create({
    container: {
        marginBottom: theme.spacing.half,
        backgroundColor: theme.color.listEntryBackground,
        borderRadius: theme.borderRadius.medium,

        ...boxShadow(theme.units.base, 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.2)'),
    }
});

export { listEntryStyle };