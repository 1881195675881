
import { SearchResponse } from "../../interfaces/backend";
import { getData } from "./base.utils";
import { FeatureType } from "@kullaberg/shared";
import {DEFAULT_LANGUAGE} from '../localization.utils';

export const getSearch = (baseUrl: string, query: string, selectedType?: string, currentLanguage?: string): Promise<SearchResponse> => {
    // const filter = `filter={"q": "${encodeURI(query)}"}`;

    const filter = {
        ...(query && { q: query, locale: currentLanguage || DEFAULT_LANGUAGE}),
    };
    const getSearchPath = () => {
        switch (selectedType) {
            case FeatureType.Poi:
                return '/search/pois';
            case FeatureType.Path:
                return '/search/paths';
            case FeatureType.Aoi:
                return '/search/aois';
            default:
                return '/search';
        }
    };

    return getData(baseUrl, 
        `${getSearchPath()}?filter=${JSON.stringify(filter)}`,
    );
}




