import { View, TouchableOpacity, Linking } from "react-native";
import { useThemedStyleFunction } from "../theme-provider";
import { socialMediaStyle } from "./social-media-list.style";
import { SocialMedia } from '../../interfaces';
import { socialMediaIcons } from '../../utils'
  
const SocialMediaList = ( props:SocialMedia) => {
const style = useThemedStyleFunction(socialMediaStyle);

const handleOpenLink = (url: string) => Linking.openURL(`https://${url}`);

return (
    <View style={style.container}>
        {Object.entries(props).map(([key, value]: [string, string | undefined]) => {
            if (!value || !socialMediaIcons[key]) return null;

            return (
            <TouchableOpacity key={key} onPress={() => handleOpenLink(value)} style={style.iconWrapper}>
                {socialMediaIcons[key]}
            </TouchableOpacity>
            );
        })}
    </View>
);
};
  
export { SocialMediaList };