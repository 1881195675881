import {
    getAoiRequirements,
    AoiRequirements,
    getPathRequirements,
    getPoiRequirements, 
    getSnowmobileRequirements,
    toPathCategories,
    toPathGpsCoordinates,
    toPaths,
    toPoiCategories,
    toPois,
    toAois,
    toAoiCategories,
    toSnowmobilePaths,
    toSnowmobileSubPaths,
    PathRequirements,
    PoiRequirements,
    SnowmobileRequirements,
    LocalizedToken,
    Path,
    Poi,
    Aoi,
    toAoiGpsCoordinates,
    SnowmobileSubPath,
    PathCategoryId,
    filterDisplayed,
    getSettingsLanguges,
    SettingsLanguagesResponse
} from "@kullaberg/shared";
import { useDataStore } from "../stores/data.store";

const fetchData = (baseUrl: string) => {
    return new Promise<void>((resolve, reject) => {
        // Fetch required data to render the application
        Promise.all([
            getPathRequirements(baseUrl),
            getSnowmobileRequirements(baseUrl),
            getPoiRequirements(baseUrl),
            getAoiRequirements(baseUrl),
            getSettingsLanguges(baseUrl),
        ])
        .then((response: [PathRequirements, SnowmobileRequirements, PoiRequirements, AoiRequirements, SettingsLanguagesResponse[]]) => {
            const [pathData, snowmobileData, poiData, aoiData, languages] = response;
            const areas: {[key: string]: LocalizedToken} = {};

            const {mainContent} = useDataStore.getState();

            // Common data shared between both the Path and SnowmobilePath system
            const formattedPathCategories = toPathCategories(pathData.categories);
            const updatedCommonFields = {
                pathCategories: formattedPathCategories,
            };

            // Path specific data
            const formattedPaths = toPaths(filterDisplayed(pathData.paths));
            const formattedPathGpsCoordinates = toPathGpsCoordinates(pathData.paths);
            const updatedPathFields = {
                paths: formattedPaths,
                pathGpsCoordinates: formattedPathGpsCoordinates,
            };
    
            // Poi specific data
            const formattedPois = toPois(filterDisplayed(poiData.pois));
            const formattedPoiCategories = toPoiCategories(poiData.categories);            
            const updatedPoiFields = {
                pois: formattedPois,
                poiCategories: formattedPoiCategories,
            };

            // Aoi specific data
            const formattedAois = toAois(filterDisplayed(aoiData.aois));
            const formattedAoiCategories = toAoiCategories(aoiData.categories);  
            const formattedAoiGpsCoordinates = toAoiGpsCoordinates(aoiData.aois);
          
            const updatedAoiFields = {
                aois: formattedAois,
                aoiCategories: formattedAoiCategories,
                aoiGpsCoordinates: formattedAoiGpsCoordinates
            };

            // Fetch snowmobile path data if snowmobile path category is included
            const formattedSnowmobilePaths = toSnowmobilePaths(snowmobileData.paths);
            const formattedSnowmobileSubPaths = toSnowmobileSubPaths(snowmobileData.subPaths);
            
            const updatedSnowmobilePathFields = {
                snowmobilePaths: formattedSnowmobilePaths,
                snowmobileSubPaths: formattedSnowmobileSubPaths,
            };

            // Add main content paths areas to used areas
            Object.values(formattedPaths).forEach((path: Path) => {
                if (mainContent.pathCategories?.length === 0 || mainContent.pathCategories?.includes(path.pathCategoryId)) {
                    path.areas.forEach((area: LocalizedToken) => {
                        const {id, name} = area;
                        areas[`${id}`] = {id, name};
                    });
                }
            });

            // Add main content poi areas to used areas
            Object.values(formattedPois).forEach((poi: Poi) => {
                if (mainContent.poiCategories?.length === 0 || mainContent.poiCategories?.includes(poi.poiCategoryId)) {
                    poi.areas.forEach((area: LocalizedToken) => {
                        const {id, name} = area;
                        areas[`${id}`] = {id, name};
                    });
                }
            });

            // Add main content aoi areas to used areas
            Object.values(formattedAois).forEach((aoi: Aoi) => {
                if (mainContent.aoiCategories?.length === 0 || mainContent.aoiCategories?.includes(aoi.aoiCategoryId)) {
                    aoi.areas.forEach((area: LocalizedToken) => {
                        const {id, name} = area;
                        areas[`${id}`] = {id, name};
                    });
                }
            });

            // Add main content snomwobile subpath areas to used areas
            Object.values(formattedSnowmobileSubPaths).forEach((snowmobileSubPath: SnowmobileSubPath) => {
                if (mainContent.pathCategories?.includes(PathCategoryId.Snowmobiling)) {
                    snowmobileSubPath.areas.forEach((area: LocalizedToken) => {
                        const {id, name} = area;
                        areas[`${id}`] = {id, name};
                    });
                }
            });

            const updatedFields = {
                ...updatedCommonFields,
                ...updatedPathFields,
                ...updatedPoiFields,
                ...updatedAoiFields,
                ...updatedSnowmobilePathFields,
                ...languages,
                areas,
            };
            console.log('updating data with fields', updatedFields);

            // Set data in data store
            useDataStore.setState(() => updatedFields);

            resolve();
        })
        .catch((error) => reject(error));
    });
};

export { fetchData };