import { View } from 'react-native';
import { useTranslation } from "react-i18next";
import { getDifficultyIcon, getDifficultyName, getElevationSpan, getMaxElevation, getMinElevation, getStatusColor, getStatusText, toKilometerString } from "../../../utils";
import { useThemedStyleFunction } from "../../theme-provider";
import { ThemedText } from '../../themed-text';
import { Path, PathCategory, PathGpsCoordinate } from '../../../interfaces';
import { getTranslatedProperty } from '../../../utils/localization.utils';
import { detailsContentStyle } from '../details-content.style';

type PathDetailsContentTopProps = Path & {
    gpsCoordinates: PathGpsCoordinate[],
    category: PathCategory,
};

const PathDetailsContentTop = (props: PathDetailsContentTopProps) => {
    const {gpsCoordinates, category, difficulty, length, status, groomedAt, tags} = props;

    const [t] = useTranslation();
    
    const DifficultyIcon = (props: any) => getDifficultyIcon(difficulty, props);
    const difficultyName = getDifficultyName(t, difficulty, false);
    
    const formattedLength = toKilometerString(length);
    
    const statusText = getStatusText(t, status, groomedAt);
    const statusColor = getStatusColor(status, groomedAt);

    const elevation = getElevationSpan(getMinElevation(gpsCoordinates), getMaxElevation(gpsCoordinates));
    const formattedElevation = `${elevation} m`;

    const formattedTags = tags.map((tag => getTranslatedProperty('name', tag)?.toLowerCase()));
    const metadata = [difficultyName, ...formattedTags].join(', ');

    const categoryName = getTranslatedProperty('name', category);

    const style = useThemedStyleFunction(detailsContentStyle);

    return (
        <View style={style.container}>
            <View style={style.row}>
                <DifficultyIcon style={style.difficultyIcon}/>
                <ThemedText style={[style.standard, style.secondary]}>{metadata}</ThemedText>
            </View>
            <View style={style.paragraph}>
                <View style={style.row}>
                    <ThemedText style={[style.standard, style.primary]} type='bold'>{formattedLength}</ThemedText>
                    <ThemedText style={[style.standard, style.primary]}> {categoryName}, </ThemedText>
                    <ThemedText style={[style.standard, style.primary]} type='bold'>{formattedElevation}</ThemedText>
                    <ThemedText style={[style.standard, style.primary]}> altitude </ThemedText>
                </View>
                <ThemedText style={[style.standard, style.capitalize, {color: statusColor}]} type='bold'>{statusText}</ThemedText>
            </View>
        </View>
    )
};

export { PathDetailsContentTop };