import React from 'react';
import { TouchableOpacity, View, ViewProps } from 'react-native';
import { CategoryListContentProps, FraListContentProps, PathListContentProps, PoiListContentProps, AoiListContentProps } from '../../interfaces';
import { withExternalStyle } from '../../utils';
import { ListEntry } from './list-entry';
import { useThemedStyleFunction } from '../theme-provider';
import { ThemedText } from '../themed-text';
import { listStyle } from './list.style';
import { t } from 'i18next';
import { ArrowForward } from '../../assets';

interface DataProvider {
    entries: PathListContentProps[] 
        | PoiListContentProps[] 
        | FraListContentProps[] 
        | CategoryListContentProps[] 
        | AoiListContentProps[],
    component: (props: any) => JSX.Element,
};

type ListProps = ViewProps & {
    emptyText: string;
    dataProviders: DataProvider[];
    resultsSuffix?: string;
    resultsLink?: string;
    onResultsPress?: (providers: DataProvider[]) => void;
    onPress?: (providerIndex: number, entryIndex: number) => void;
};

const List = (props: ListProps) => {
    const {style: styleProp, emptyText, dataProviders, resultsSuffix, resultsLink, onResultsPress, onPress} = props;

    const style = useThemedStyleFunction(listStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    const count = dataProviders.reduce((acc, provider) => {
        acc += provider.entries.length;
        return acc;
    }, 0);

    return (
        <View style={containerStyle}>
            { (count > 0 && (resultsSuffix || (resultsLink && onResultsPress))) 
                ? <View style={style.resultsRow}>
                    <ThemedText style={style.resultsText}>
                        {resultsSuffix ? `${count} ${resultsSuffix}` : undefined}
                    </ThemedText>

                    { (resultsLink && onResultsPress)
                        ? <TouchableOpacity style={style.resultsLinkTouchable} onPress={() => onResultsPress(dataProviders)}>
                            <ThemedText style={style.resultsLinkText} type={'bold'}>
                                {resultsLink}
                            </ThemedText>
                            <ArrowForward style={style.resultsLinkIcon}/>
                        </TouchableOpacity>
                        : null
                    }
                </View>
                : null
            }
            { dataProviders.map((provider, providerIndex) => 
                provider.entries.map((entry, entryIndex) => (
                    <ListEntry key={`${providerIndex}${(entry as any)?.id ?? entryIndex}`}>
                        <TouchableOpacity onPress={() => onPress?.(providerIndex, entryIndex)}>
                            <provider.component {...entry} />
                        </TouchableOpacity>
                    </ListEntry>
                ))
            )}
            { count == 0 ? <ThemedText>{ emptyText }</ThemedText> : null }
        </View>
    );
}

export { List, DataProvider };