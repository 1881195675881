export enum DisplayStatus {
    Display = "DISPLAY",
    Hide = "HIDE",
    Scheduled = "SCHEDULED",
};

export enum AoiType {
    Warning = 'AOI_CATEGORY_1',
    Important = 'AOI_CATEGORY_2',
    Info = 'AOI_CATEGORY_3',
  }