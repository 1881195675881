import i18n, {
    LanguageDetectorAsyncModule,
    Services,
    InitOptions,
} from 'i18next';
import {initReactI18next} from 'react-i18next'; 
import AsyncStorage from '@react-native-async-storage/async-storage';
import { DEFAULT_LANGUAGE } from '@kullaberg/shared';
// import 'moment/min/locales';
// import moment from 'moment';
import en from './en';
import sv from './sv';
import de from './de';
  
export const AVAILABLE_LANGUAGES = {
    en,
    sv,
    de
};
  
const languageDetector: LanguageDetectorAsyncModule = {
    type: 'languageDetector',
    // If this is set to true, your detect function receives a callback function that you should call with your language,
    //useful to retrieve your language stored in AsyncStorage for example
    async: true,
    init: (
        _services: Services,
        _detectorOptions: Record<string, unknown>, //object,
        _i18nextOptions: InitOptions,
    ) => {
        // Use services and options
    },
    detect: (callback: (lng: string) => void) => {
        AsyncStorage.getItem('APP_LANG', (err, lng) => {
            // Handle error fetching stored data or no data stored case
            if (err || !lng) {
                if (err) {
                    console.log('Error fetching "APP_LANG" from async store', err);
                } else {
                    console.log('No language is set, choosing the best available or English as fallback');
                }

                callback(DEFAULT_LANGUAGE);
                return;
            }

            callback(lng);
        });
    },
    cacheUserLanguage: (lng: string) => AsyncStorage.setItem('APP_LANG', lng),
};
  
i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: DEFAULT_LANGUAGE,
      resources: AVAILABLE_LANGUAGES,
      react: {
        useSuspense: false,
      },
      interpolation: {
        escapeValue: false,
        format: function (value: any, format?: string, lng?: string) {
          switch (format) {
            case 'flags':
              if (
                typeof value !== 'number' ||
                value < 1 ||
                !Number.isInteger(value)
              ) {
                return value;
              }
              if (lng === 'sv') {
                return [...Array(value as number)].map((_) => '🇸🇪').join(' ');
              } else if (lng === 'de') {
                return [...Array(value as number)].map((_) => '🇩🇪').join(' ');
              } else {
                return [...Array(value as number)].map((_) => '🌎').join(' ');
              }
            default:
              return value;
          }
        },
      },
      defaultNS: 'common',
    });
  
export const t = i18n.t.bind(i18n);
  
export const getCurrentLng = () =>
    i18n.language || '';

export const getTranslatedProperty = (
  key: string,
  object: Record<any, any>,
) => {
  if (!key || !object || Object.keys(object).length === 0) return '';

  const currentLang = getCurrentLng();
  return object[key]?.[currentLang] ?? object[key]?.[DEFAULT_LANGUAGE] ?? ''
};
  
i18n.on('languageChanged', (lng: string) => {
    console.log('onLanguageChanged');
    // moment.locale(lng);
});