import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";
import { Platform } from "react-native";

const languageSelectorStyle = (theme: Theme) => StyleSheet.create({
    container: {
        //
    },

    text: {
        marginBottom: theme.spacing.half,
        fontSize: theme.fontSize.standard,
        color: theme.color.textPrimary,
        textAlign: 'center',
        textTransform: 'uppercase',
    },

    textInverted: {
        color: theme.color.textInverted,
    },

    row: {
        flexDirection: 'row',
        justifyContent: 'center',
    },

    touchable: {
        marginHorizontal: theme.spacing.sliver,
        borderRadius: theme.borderRadius.standard,
        ...Platform.select({
            ios: {
                padding: theme.spacing.sliver * 2,
            },
            android: {
                padding: theme.spacing.sliver * 2,
            },
            default: {
                paddingHorizontal: theme.spacing.sliver * 2,
            },
          }),
    },

    selected: {
        backgroundColor: theme.color.flagsBackground,
    },

    selectedInverted: {
        backgroundColor: theme.color.textInverted,
    },

    flag: {
        width: theme.iconSize.small * 3,
        aspectRatio: 'auto',
    },
});

export { languageSelectorStyle };