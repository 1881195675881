import { SnowmobilePathResponse, SnowmobileRequirements, SnowmobileSubPathResponse } from "../../interfaces";
import { getData } from "./base.utils";

export const getSnowmobilePaths = (baseUrl: string): Promise<SnowmobilePathResponse[]> => {
    const path = '/snowmobile/trails';
    return getData<SnowmobilePathResponse[]>(baseUrl, path);
};

export const getSnowmobileSubPaths = (baseUrl: string): Promise<SnowmobileSubPathResponse[]> => {
    const path = '/snowmobile/subpaths/gps';
    return getData<SnowmobileSubPathResponse[]>(baseUrl, path);
};

/**
 * Gets the required data to render the snowmobile paths
 */
export const getSnowmobileRequirements = (baseUrl: string): Promise<SnowmobileRequirements> => {
    return new Promise<SnowmobileRequirements>((resolve, reject) => {
        Promise.all([
            getSnowmobilePaths(baseUrl),
            getSnowmobileSubPaths(baseUrl),
        ]).then((response: [SnowmobilePathResponse[], SnowmobileSubPathResponse[]]) => {
            const [paths, subPaths] = response;

            resolve({paths, subPaths});
        }).catch((error) => reject(error));
    });
};