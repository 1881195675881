import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";

const listStyle = (theme: Theme) => StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingTop: theme.spacing.standard,
        paddingBottom: theme.spacing.standard,
        paddingLeft: theme.spacing.standard,
        paddingRight: theme.spacing.standard,
        backgroundColor: theme.color.listBackground,
    },

    resultsRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing.standard,
    },

    resultsText: {
        fontSize: theme.fontSize.standard,
        color: theme.color.textSecondary,
    },

    resultsLinkTouchable: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    resultsLinkText: {
        fontSize: theme.fontSize.standard,
        color: theme.color.textLink,
    },

    resultsLinkIcon: {
        width: theme.iconSize.medium,
        height: theme.iconSize.medium,
        marginLeft: theme.spacing.half,
        color: theme.color.textLink,
    }
});

export { listStyle };